import React, { useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import Header from '../components/Header';
import Footer from '../components/Footer';
import banner from '../images/banner.jpg';

import api from '../services/api';

import { store, persistor } from '../store';

import { View, Container, Banner } from '../components/pages/styles';

export default function History() {
  const [history, setHistory] = useState('');

  useEffect(() => {
    api.get('history/1').then(response => {
      setHistory(response.data.body);
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
            <SEO title="Historia" />
            <Header />
            <View>
              <Banner>
                <img src={banner} alt="adote um cão" />
              </Banner>
              <Container>
                <div className="bloco">
                <h3>Historia</h3>
                {ReactHtmlParser(history)}
                </div>
              </Container>
            </View>

            <Footer />

            <ToastContainer autoClose={3000} />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
