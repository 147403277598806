import React from 'react';

import { Link } from 'gatsby';
import { getYear } from 'date-fns';

import logo from '../../images/logo-branca.svg';

import { Footer, FinalFooter, Container } from './styles';

const Rodape = () => (
  <Footer>
    <Container>
      <ul>
        <li>
          <Link to="/history">História</Link>
        </li>
        <li>
          <Link to="/parceiros">Parceiros</Link>
        </li>
        <li>
          <Link to="/eventos">Eventos</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
      </ul>
      <img src={logo} alt="Adotapet" />
    </Container>
    <FinalFooter>
      <p>
        Copyright© {getYear(new Date())} ADOTAPET -{' '}
        <Link to="/politica-de-privacidade">Termo de uso</Link>
      </p>

      <p>by fabiomoraes.dev | version 1.2.8</p>
    </FinalFooter>
  </Footer>
);

export default Rodape;
