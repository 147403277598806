import styled from 'styled-components';
import { shade } from 'polished';

export const Footer = styled.div`
  display: block;
  width: 100%;
  margin-top: 10px;
  background: #1497e4;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;

  img {
    margin-top: 12px;
    width: 140px;
  }

  @media only screen and (min-width: 481px) {
    flex-direction: row;
    justify-content: space-between;

    img {
      margin-top: 12px;
    }
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;

    img {
      margin-top: 0;
    }
  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;

    img {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    display: flex;

    li {
      font-size: 12px;

      & + li {
        margin-left: 10px;
      }

      a {
        color: #fff;

        &:hover {
          color: ${shade(0.3, '#fff')};
        }
      }
    }
  }
`;

export const FinalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 15px;
  padding-bottom: 15px;
  flex: 1;
  color: #fff;
  justify-content: center;
  border-top: 1px dotted #eee;

  @media only screen and (min-width: 481px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    color: #fff;
  }

  p {
    font-size: 11px;
  }
`;
